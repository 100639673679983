module.exports = {
  pathPrefix: '/',
  title: 'Alexx & the Mooonshiners',
  titleAlt: 'Alexx & the Mooonshiners',
  description: `La furieuse bluesgirl est de retour et ce n'est pas pour peigner du pecari`,
  headline: 'Alexx & the Mooonshiners',
  url: 'https://www.mooonshiners.com',
  logo: 'static/favicon.png',
  ogLanguage: 'fr_FR',

  // Prismic.io
  PrismicRepository: 'mooonshiners',

  // JSONLD / Manifest
  favicon: 'static/favicon.png',
  shortName: 'alexxandthemooonshiners',
  author: 'Alexx & the Mooonshiners',
  themeColor: '#ffffff',
  backgroundColor: '#ffffff',
  skipNavId: 'reach-skip-nav'
}
