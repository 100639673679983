import { Link } from 'gatsby'
import styled from '@emotion/styled'

export const DesktopMenuContainer = styled.ul`
  font-size: ${props => props.theme.fonts.menu.size};
  font-family: ${props => props.theme.fontFamily};
  margin: 0;
  padding: .4rem 0;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-around;
  * {
    margin: 0;
    list-style-type: none;
  }
  li {
    padding: 0.5em 1rem;
    &:first-of-type {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    a {
      font-style: normal;
      text-transform: uppercase;
      color: ${props => props.theme.colors.grey};
      text-decoration: none;
      font-weight: 400
    }
    ul {
      display: flex;
      position: absolute;
      li {
        padding: 0.5em;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`
export const MenuItemsContainer = styled.li`
  &:hover {
    ul {
      display: flex!important;
    }
  }
`
export const MenuItemContainer = styled(Link)`
  color: ${props => props.active}!important;
  &:hover {
    color: ${props => props.theme.colors.grey}!important;
  }
`
export const MenuItemContainerSimple = styled.a`
  text-decoration: none;
  color: ${props => props.active}!important;
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.grey}!important;
  }
  &:focus {
    text-decoration: none;
  }
  &:active {  
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
`

export const MenuItemContainerSimpleMobile = styled.a`
  text-decoration: none;
  color: black;
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.black}!important;
  }
  &:focus {
    text-decoration: none;
  }
  &:active {  
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
`

export const SubMenuContainer = styled.ul`
  display: ${props => props.active}!important;
  padding: 0;
  width: 100%;
  left: 0;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.background};

  li {
    a {
      font-size: 14px;
    }
  }
`