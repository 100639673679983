import React from 'react'
// import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { Hero, HeroInner } from '../containers/Header'
import { DesktopMenu, MobileMenu } from './Menu'

const Header = (props) => {
  return (
    <React.Fragment>
      <Hero>
        <MobileMenu {...props} />
        <HeroInner>
          {
            !props.isIndex && (
              <p className="h1">
                <Link to="/">Alexx <br /><span>& the Mooonshiners</span></Link>
              </p>
            )
          }
          <DesktopMenu {...props} />
        </HeroInner>
      </Hero>
    </React.Fragment>
  )
}

export default Header;