import React from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import i18n from '../../config/i18n'

const SEO = ({ 
  title, 
  desc, 
  pathname, 
  modalBanner
}) => {
  const { 
    site, 
    prismicHomepage
  } = useStaticQuery(query)
  const { 
    defaultTitle, 
    defaultDescription, 
    siteLanguage, 
    headline 
  } = i18n['fr-fr']
  
  const {
    buildTime,
    siteMetadata: { 
      siteUrl, 
      author
    },
  } = site

  const homeURL = `${siteUrl}`
  const ogImage = prismicHomepage.data.image.localFile.publicURL;
  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${ogImage}`,
    url: `${siteUrl}${pathname || ''}`,
  }

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: `${homeURL}${pathname}`,
    headline: title || headline.headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: `${homeURL}${pathname}`,
    description: desc || defaultDescription,
    name: defaultTitle,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: '2021',
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
    },
    datePublished: '2021-04-20T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: ogImage,
    },
  }

  // Initial SiteNavigation
  const itemListElement = [
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-bio",
      "name": "Bio",
      "url": `https://prismic-io.s3.amazonaws.com/mooonshiners/642f4f1c-29a0-4739-a90b-5af4442a8552_Alexx+A4+%281%29.pdf`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-photos-videos",
      "name": "Photos/Vidéos",
      "url": `${homeURL}/photos-et-videos/`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-presse",
      "name": "Presse",
      "url": `${homeURL}/presse/`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-booking",
      "name": "Contact/Booking",
      "url": `${homeURL}/booking/`
    },
  ]

  const SiteNavigation = {
    '@context': 'http://schema.org',
    "@graph": itemListElement
  }

  return (
    <>
      <Helmet
        title={seo.title}
        style={[{
          "cssText": `
            html,
            body {
              position: ${modalBanner ? 'fixed' : 'relative'};
              height: ${modalBanner ? '100%' : 'auto'}
              overflow: ${modalBanner ? 'hidden!important' : 'auto'}
            }
          `
        }]}
        defer={false}
      >
        <html lang={siteLanguage} />

        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="title" content={seo.title} />

        <meta property="og:site_name" content="Alexx & the Mooonshiners" />
        <meta property="og:locale" content="fr" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta property="og:image:alt" content={seo.description} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="mooonshiners.com"></meta>
        <meta property="twitter:url" content={seo.url}></meta>
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />

        <link rel="canonical" href={seo.url}></link>

        <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
        <script type="application/ld+json">{JSON.stringify(SiteNavigation)}</script>
      </Helmet>
    </>
  )
}

const mapStateToProps = (state) => ({
  ...state.modalBanner
});
export default connect(mapStateToProps)(SEO);

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object,
}

SEO.defaultProps = {
  title: null,
  desc: null,
  pathname: null,
  article: false,
  node: null,
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        ogLanguage
        author
      }
    }
    prismicHomepage {
      data {
        image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`