import styled from '@emotion/styled'

export const Hero = styled.header`
  display: flex;
  align-items: center;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: block;
  }
  > div {
    div {
      &:last-of-type {
        display: none;
        @media (max-width: ${props => props.theme.breakpoints.m}) {
          display: block;
        }
      };
    }
  }
`
export const Wrapper = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 0 2rem;
`
export const HeroInner = styled(Wrapper)`
  padding-top: 2rem;
  padding-bottom: 1rem;
  h1, p.h1 {
    margin: 0 0 0 0;
    padding: 0 0 .5em;
    font-size: 52px;
    line-height: 0.8em;
    text-align: right;
    font-family: ${props => props.theme.fonts.titles};
    color: #fff;
    line-height: 32px;
    span {
      font-size: 36px;
    }
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      text-align: left;
      padding-left: 0;
      margin-top: .5rem;
      margin-bottom: 0;
      font-size: 48px;
      line-height: 0.6em;
      span {
        font-size: 28px;
      }
    }
    a, a:hover, a:focus, a:visited {
      color: #fff;
      font-style: normal;
      text-decoration: none;
    }
    span {
      font-weight: 200
    }
    small {
      display: block;
      font-family: ${props => props.theme.fonts.header.medium};
      font-size: 14px;
      margin-top: .2rem;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }
`