import React from 'react'
import { 
  DesktopMenuContainer, 
  MenuItemsContainer,
  MenuItemContainer,
  MenuItemContainerSimple
} from '../containers/DesktopMenu'
import styled from '@emotion/styled'
import theme from '../styles/theme'
import { push as Menu } from 'react-burger-menu'
import { Link } from 'gatsby'

const MenuItems = [
  {
    uid: 'bio',
    parent: null,
    name: 'Bio',
    type: 'dist',
    pathName: 'https://prismic-io.s3.amazonaws.com/mooonshiners/642f4f1c-29a0-4739-a90b-5af4442a8552_Alexx+A4+%281%29.pdf',
  },
  {
    uid: 'photos-et-videos',
    parent: null,
    name: 'Photos/Vidéos',
    type: 'local',
    pathName: '/photos-et-videos/',
  },
  {
    uid: 'presse',
    parent: null,
    name: 'Presse',
    type: 'local',
    pathName: '/presse/',
  },
  {
    uid: 'booking',
    parent: null,
    name: 'Contact/Booking',
    type: 'local',
    pathName: '/booking/',
  },
];

const MobileMenuWrapper = styled(Menu)`
  > div {
    nav {
      ul {
        outline: none;
        margin: 0;
        li {
          list-style-type: none;
          a {
            font-family: ${props => props.theme.fontFamily};
            font-style: normal;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: 400;
            padding: .5rem;
            display: block!important;
            @media (max-width: ${props => props.theme.breakpoints.m}) {
              display: inline-block!important;
            }
          }

          ul {
            margin: 0 0 0 15px;
            padding: 0;
            li {
              margin: 0;
              padding: 1px 0;
              a {
                padding: 0;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
`

const MobileMenuItem = styled(Link)`
  color: ${props => props.active}!important;
`;

var BurgerMenuStyles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '36px',
    height: '30px',
    right: '25px',
    top: '24px',
    'zIndex': 2
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0
  },
  bmMenu: {
    background: '#f2f2f2',
    padding: '0.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(242, 242, 242, 0.7)',
    filter: 'blur(1.5rem)',
    top: 0
  }
}

export const DesktopMenu = (props) => {
  const { location } = props;
  
  return (
    <React.Fragment>
      <DesktopMenuContainer itemScope itemType="http://www.schema.org/SiteNavigationElement">
        {
          MenuItems.map((item, index) => {
            return (
              <MenuItem 
                key={`menu-item-${index}`} 
                {...item} 
                type={item.type}
                path={location.pathname}
              />
            )
          })
        }
      </DesktopMenuContainer>
    </React.Fragment>
  );
}

export const MobileMenu = (props) => {
  return (
    <MobileMenuWrapper right styles={BurgerMenuStyles}>
      <ul>
        <li>
          <a 
            href={`https://prismic-io.s3.amazonaws.com/mooonshiners/642f4f1c-29a0-4739-a90b-5af4442a8552_Alexx+A4+%281%29.pdf`} 
            target="_blank"
            rel="noreferrer"
          >
            Bio
          </a>
        </li>

        <li>
          <MobileMenuItem to={`/photos-et-videos/`}>
            Photos/Vidéos
          </MobileMenuItem>
        </li>

        <li>
          <MobileMenuItem to={`/presse/`}>
            Presse
          </MobileMenuItem>
        </li>

        <li>
          <MobileMenuItem to={`/booking/`}>
            Contact/Booking
          </MobileMenuItem>
        </li>
      </ul>
    </MobileMenuWrapper>
  );
}

const MenuItem = (props) => {
  return (
    <MenuItemsContainer itemProp="name">
      {
        props.type === 'dist' ? (
          <MenuItemContainerSimple
            target="_blank"
            href={props.pathName}
            id={`nav-${props.uid}`}
            itemProp="url"
            active={(props.path === props.pathName) ? theme.colors.grey : theme.colors.text}
          >
            {props.name}
          </MenuItemContainerSimple>
        ) : (
          <MenuItemContainer
            to={`/${props.special ? props.special : props.uid}/`}
            id={`nav-${props.uid}`}
            itemProp="url"
            active={(props.path === props.pathName) ? theme.colors.grey : theme.colors.text}
          >
            {props.name}
          </MenuItemContainer>
        )
      }
    </MenuItemsContainer>
  );
}
